<script setup lang="ts">
  import type { Organization } from '~~/types/organization'

  const props = defineProps<{
    className: string,
    pageTitle: string,
    reloadTime: Date,
    selectedOrg: Organization,
    topPageFlg: boolean,
  }>()

  const emits = defineEmits<{
    (e: 'historyBack'): void,
    (e: 'logout'): void,
    (e: 'reloadScreen'): void,
    (e: 'showSelectClassroomModal'): void,
  }>()

</script>
<template>
  <div
    class="
      common-background-pink
      flex
      items-center
      my-2
      tab:mx-4
      sp:mx-2
    "
  >
    <!-- タイトル部分 -->
    <div
      class="
        flex
        items-center
        tab:w-5/12
        sp:w-4/12
      "
    >
      <span
        v-if="!props.topPageFlg"
        class="
          material-icons
          md-36
          md-light
          mr-2
        "
        @click="emits('historyBack')"
      >
        chevron_left
      </span>
      <!-- 教室名 -->
      <span
        class="
          font-semibold
          text-2xl
          text-white
          tab-inline
        "
      >
        {{ props.topPageFlg ? props.className : props.pageTitle }}
      </span>
    </div>
    <div
      class="
        sp-block
        sp:text-center
        sp:w-4/12
      "
    >
      <!-- 画面名(スマホ) -->
      <span
        class="
          font-semibold
          sp-inline
          text-lg
          text-white
          whitespace-nowrap
        "
      >
        {{ props.pageTitle }}
      </span>
    </div>
    <!-- コントロール部分 -->
    <div
      class="
        flex
        items-center
        justify-end
        tab:w-7/12
        sp:w-4/12
      "
    >
      <common-refresh-time
        v-if="props.topPageFlg"
        :reload-time="props.reloadTime"
        class="
          tab-block
          tab:mx-4
          text-right
        "
      >
      </common-refresh-time>
      <common-refresh-btn
        v-if="props.topPageFlg"
        class="
          tab-block
          tab:mx-4
        "
        @click="emits('reloadScreen')"
      >
      </common-refresh-btn>
      <div
        class="
          tab:border-l
          tab:border-pink-300
          tab:px-4
        "
      >
        <common-header-switch-classroom-btn
          v-if="props.topPageFlg"
          class="
            tab:mx-4
          "
          @click="emits('showSelectClassroomModal')"
        >
        </common-header-switch-classroom-btn>
      </div>
      <div
        class="
          font-semibold
          logout-text
          pl-4
          text-center
          text-white
          whitespace-nowrap
        "
        @click="emits('logout')"
      >
        <span
          class="
            logout-icon
            material-icons
          "
        >
          logout
        </span>
        <div
          class="
            tab:text-base
          "
        >
          ログアウト
        </div>
      </div>
    </div>
  </div>

  <common-refresh-bar
    v-show="props.topPageFlg"
    :reload-time="props.reloadTime"
    class="
      sp-block
      w-full
    "
    @click="emits('reloadScreen')"
  >
  </common-refresh-bar>
</template>
<style scoped>
  @media (max-width: 767px) {
    .logout-text {
      font-size: 8pt;
    }
    .logout-icon {
      font-size: 18px;
    }
  }
  @media (min-width: 768px) {
    .logout-icon {
      font-size: 36px;
    }
  }
</style>
