<script setup lang="ts">
  import { formatDateForHeader } from '~/libs/formatDateForHeader'
  const props = defineProps<{
    reloadTime: Date,
  }>()

  const emits = defineEmits<{
    (e: 'reloadScreen'): void,
  }>()

</script>

<template>
  <div
    class="
      bg-gray-100
      common-text-gray
      font-medium
      h-8
      refresh-bar
      text-center
      text-sm
    "
  >
    <span
      class="
        align-middle
        h-8
      "
    >
      {{ formatDateForHeader(props.reloadTime) }} 時点
    </span>
    <button
      class="
        align-middle
        h-8
        material-icons
        px-2
      "
      @click = "emits('reloadScreen')"
    >
      refresh
    </button>
  </div>
</template>
