<script setup lang="ts">
  const emits = defineEmits<{
    (e: 'click'): void,
  }>()
</script>
<template>
  <common-btn
    btn-type="nonary"
    round-type="rounded-sm"
    class="
      sp-block
    "
    @click="emits('click')"
    data-gtm="switch-classroom"
  >
    <span
      class="
        whitespace-nowrap
      "
      data-gtm="switch-classroom"
    >教室</span>
    <span
      class="
        whitespace-nowrap
      "
      data-gtm="switch-classroom"
    >切替</span>
  </common-btn>
  <common-btn
    btn-type="secondary"
    round-type="rounded"
    class="
      tab-block
    "
    @click="emits('click')"
    data-gtm="switch-classroom"
  >
    <span
      class="
        align-middle
        material-icons
      "
      data-gtm="switch-classroom"
    >
      home
    </span>
    <span
      class="
        whitespace-nowrap
      "
      data-gtm="switch-classroom"
    >
      教室切替
    </span>
  </common-btn>
</template>
