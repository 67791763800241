<script setup lang="ts">
  import { useUnreadCountManagerStore } from '~/store/pinia/unread-count-manager'

  const props = defineProps<{
    pageType: string,
    isDisagreeMenu: boolean,
  }>()

  const navItemArr: Array<{
    name: string,
    path: string,
    iconName: string,
    displayName: string,
    isHidden: boolean,
  }> = [
    {
      name: 'home',
      path: '/home',
      iconName: 'home',
      displayName: 'ホーム',
      isHidden: false,
    },
    {
      name: 'messages',
      path: '/messages',
      iconName: 'email',
      displayName: '連絡確認',
      isHidden: props.isDisagreeMenu,
    },
    {
      name: 'notifications',
      path: '/notifications',
      iconName: 'campaign',
      displayName: 'お知らせ',
      isHidden: props.isDisagreeMenu,
    },
    {
      name: 'others',
      path: '/others',
      iconName: 'more_horiz',
      displayName: 'その他',
      isHidden: false,
    },
  ]
</script>
<template>
  <div
    class="
      common-background-pink
    "
  >
    <ul
      class="
        flex
        justify-evenly
      "
    >
      <li
        v-for="navItem in navItemArr"
        :key="navItem.name"
        class="
          mt-2
          mb-1
          md:mb-2
          text-center
        "
        :class="
          navItem.isHidden ? 'hidden' : ''
        "
      >
        <div
          class="
            relative
          "
        >
          <nuxt-link
            :class="props.pageType === navItem.name ? 'text-white' : 'text-pink-200'"
            :to="navItem.path"
          >
            <div
              class="
                material-icons
              "
            >
              {{navItem.iconName}}
            </div>
            <div
              class="
                md:text-sm
                text-xs
              "
            >
              {{navItem.displayName}}
            </div>
          </nuxt-link>
          <common-unread-badge
            v-if="navItem.name=='messages' && useUnreadCountManagerStore().unreadMessageCount > 0"
          >
            {{useUnreadCountManagerStore().unreadMessageCount}}
          </common-unread-badge>
        </div>
      </li>
    </ul>
  </div>
</template>
