<template>
  <common-btn
    btn-type="quaternary"
    round-type="rounded"
  >
    <span
      class="
        material-icons
        align-middle
      "
    >
      refresh
    </span>
    <span
      class="
        whitespace-nowrap
      "
    >
      画面の更新
    </span>
  </common-btn>
</template>
