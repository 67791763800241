export function formatDateForHeader (reloadTime: Date) {
  const month = (reloadTime.getMonth() + 1).toString().padStart(2, '0');
  const day = reloadTime.getDate().toString().padStart(2, '0');
  const weekDay = weekDayJpn[reloadTime.getDay()];
  const hour = reloadTime.getHours().toString().padStart(2, '0');
  const minute = reloadTime.getMinutes().toString().padStart(2, '0');
  return month + '/' + day + '（' + weekDay + '） ' + hour + ':' + minute;
}

const weekDayJpn: {
  [key: number]: string;
} = {
  0: '日',
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
}
