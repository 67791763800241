<script setup lang="ts">
  import { formatDateForHeader } from '~/libs/formatDateForHeader'
  const props = defineProps<{
    reloadTime: Date,
  }>()

  const formatReloadTime = formatDateForHeader(props.reloadTime)
  const date = formatReloadTime.split(' ')[0]
  const time = formatReloadTime.split(' ')[1]
</script>
<template>
  <div
    class="
      font-semibold
      text-white
    "
  >
    <span
      class="
        whitespace-nowrap
      "
    >
      {{ date }}
    </span>
    <span
      class="
        whitespace-nowrap
      "
    >
      {{ time }} 時点
    </span>
  </div>
</template>
